import {Spinner} from 'react-bootstrap'
import TagsInput from '../../components/TagsInput'
import { Form, Formik } from 'formik'
import React, { useEffect, useState, useContext, createContext } from 'react'
import Email from '../../main/FormFields/Email'
import * as Yup from 'yup'
import { api, defaultFilters } from '../../utils/index'
import { ToastContainer, toast } from 'react-toastify'
import { notify } from '../../utils/notify'
import {
	Col,
	FormGroup,
  InputGroup,
	FormText,
	CardBody,
	Row,
	Button,
	Card,
	CardHeader,
	Input,
	Container
} from "reactstrap";
import { useParams } from 'react-router-dom'
import FiltersGSCModal from "../../components/molecules/FiltersGSCModal";

const EmailContext = createContext()

const SiteUrlManageForm = () => {
  const params = useParams()
  const redirectPath = '/seo-sentinel/site-urls'
  const [siteUrl, setSiteUrl] = useState({})
  const [loading, setLoading] = useState(false)
  const [showFiltersGSCModal, setShowFiltersGSCModal] = useState(false)
  const [emails, setEmails] = useState([])

  // site url fields
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [secretName, setSecretName] = useState('')
  const [filters, setFilters] = useState(defaultFilters)
  const [siteurlSitemaps, setSiteurlSitemaps] = useState([])
  const [active, setActive] = useState(false)
  const [alertEventsActive, setAlertEventsActive] = useState([])
  
  const [secrets, setSecrets] = useState([])
  const [alertEvents, setAlertEvents] = useState([])

  const getGscSecrets = () => {
    api
      .get('get_gsc_secret/')
      .then(res => {
        for(let x in res.data){
          res.data[x] = res.data[x].split('/')[res.data[x].split('/').length-1]
        }
        res.data.unshift('')
        setSecrets(res.data)
      })
      .catch(err => console.error(err))
  }

  const getAlertEvents = () => {
    api
      .get('/seo-sentinel/alert-events-active/')
      .then(res => {
        setAlertEvents(res.data)
      })
      .catch(err => console.error(err))
  }

  const getSiteUrl = (id) => {
    setLoading(true)

    api.get(`/seo-sentinel/site-urls/${id}/`)
      .then(response => {
        setSiteUrl(response.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        notify(
          err,
          'error'
        )
      })
  }

  useEffect(() => {
    getGscSecrets()
    getAlertEvents()
    
    if (params?.id) {
      getSiteUrl(params.id)
    }
    else {
      setSiteUrl({
        name: '',
        url: '',
        secret_name: '',
        filters: defaultFilters,
        alert_events_active: [],
        notification_emails: '',
        active: false,
      })
    }
  }, [])

  useEffect(() => {
    const {
      name,
      url,
      secret_name,
      filters,
      siteurl_sitemaps,
      alert_events_active,
      notification_emails,
      active
    } = siteUrl

    const tempFilters = parseFilters(filters || defaultFilters)

    setName(name || '')
    setUrl(url || '')
    setSecretName(secret_name || '')
    setFilters(tempFilters)
    setSiteurlSitemaps(siteurl_sitemaps || [])
    setAlertEventsActive(alert_events_active || [])

    let site_url_emails = []
    if (notification_emails) {
      site_url_emails = notification_emails.replaceAll(' ','').split(",")
    }
    setEmails(site_url_emails)

    setActive(active || false)

  }, [siteUrl])

  const deleteSitemapIndex = index => {
    setSiteurlSitemaps(siteurlSitemaps.filter((obj, i) => i != index))
  }

  const addSitemapIndex = index => {
    const newSitemapIndex = {
      url: ''
    }
    setSiteurlSitemaps([...siteurlSitemaps, newSitemapIndex])
  }

  const deleteFilterIndex = index => {
    const filtersTemp = filters
    const newFilters = filtersTemp[0].filters.filter((obj, i) => i != index)
    filtersTemp[0].filters = newFilters
    setFilters([...filtersTemp])
  }

  const parseFilters = (f) => {
    try {
      if (typeof f === 'string') {
        const jsonParsed = JSON.parse(f)
        return jsonParsed
      }
      return f
    } catch (e) {
      console.error(e)
    }
  }

  const prepareSubmitData = values => {
    let formJson = {
      name: values.name,
      url: values.url,
      secret_name: values.secret_name, 
      filters: JSON.stringify(values.filters, null, true),
      alert_events_active: values.alert_events_active,
      notification_emails: emails.toString(),
      active: values.active
    }

    formJson['siteurl_sitemaps'] = siteurlSitemaps

    return JSON.stringify(formJson)
  }

  const handleCreate = (values, {setSubmitting}) => {
    const formData = prepareSubmitData(values)

    api.post('/seo-sentinel/site-urls/', formData, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      setSubmitting(false)
      if (response.status === 201) {
        notify('Site creato con successo.', 'success')
        window.location = redirectPath
      } else {
        notify(
          'Non è stato possibile creare il site url, riprovare o contattare un Innovation',
          'error'
        )
      }
    })
    .catch(err => {
      setSubmitting(false)
      notify(
        (err?.response?.data?.error) ? err?.response?.data?.error : err.message,
        'error'
      )
    })
  }

  const handleUpdate = (values, {setSubmitting}) => {
    const formData = prepareSubmitData(values)

    api.put('/seo-sentinel/site-urls/' + params?.id + '/', formData, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => {
      setSubmitting(false)
      if (response.status === 200) {
        notify('Modifiche applicate con successo.', 'success')
        //window.location = redirectPath
      }
    })
    .catch(err => {
      setSubmitting(false)
      notify(
        (err?.response?.data?.error) ? err?.response?.data?.error : err.message,
        'error'
      )
    })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(255, "Name must be less than 255 characters long")
      .required(),
    url: Yup.string()
      .max(255, "Url must be less than 255 characters long")
      .required(),
    secret_name: Yup.string()
      .nullable()
      .optional()
      .max(100, "Secret name must be less than 100 characters long"),
    filters: Yup.string()
      .nullable()
      .optional(),
    //notification_emails: Yup.array().required(),
    active: Yup.boolean().required()
  })

  return (
    <>
      {loading ? (
        <Spinner animation="grow" />
      ) : (
        <Row style={{marginBottom: "100px"}}>
          <Formik
            initialValues={{
              name: name,
              url: url,
              secret_name: secretName,
              filters: filters,
              alert_events_active: alertEventsActive,
              notification_emails: emails,
              active: active,
            }}
            onSubmit={(params?.id) ? handleUpdate : handleCreate}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              defaultValue
            }) => (
              <div className="col pb-5">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                  <Row style={{ marginLeft: "0px" }}>
                    <Col md={10}>
                      <h1 className="mb-0">{(params?.id) ? 'Modifica' : 'Aggiungi'} site da analizzare</h1>
                    </Col>
                  </Row>
                  </CardHeader>
                  <CardBody>
                    <FiltersGSCModal show={showFiltersGSCModal} onHide={setShowFiltersGSCModal} onSubmit={() => setShowFiltersGSCModal(false)} filters={filters} setFilters={setFilters} />
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label htmlFor="name" className="form-control-label">
                              Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="name"
                              type={'text'}
                              value={values.name}
                              onChange={event => {
                                handleChange(event)
                                setFieldValue('name', event.target.value)
                              }}
                            >
                            </Input>
                            {(errors.name) ? <FormText color="danger" align={'left'}> {errors.name} </FormText> : null}
                          </FormGroup>
                          <FormGroup>
                            <label htmlFor="url" className="form-control-label">
                              Nome GSC
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="url"
                              type={'text'}
                              value={values.url}
                              onChange={event => {
                                handleChange(event)
                                setFieldValue('url', event.target.value)
                              }}
                            >
                            </Input>
                            {(errors.url) ? <FormText color="danger" align={'left'}> {errors.url} </FormText> : null}
                          </FormGroup>
                          <FormGroup>
                            <label htmlFor="secret_name" style={{ display: 'block' }}>
                              Secret Name
                            </label>
                            <Input
                              type="select"
                              className="form-control-alternative"
                              id="secret_name"
                              value={values.secret_name}
                              required
                              style={{ height: '44px' }}
                              onChange={event => {
                                handleChange(event)
                                setFieldValue('secret_name', event.target.value)
                              }}
                            >
                              {secrets.map(secret => {
                                return (
                                  <option key={secret} value={secret}>
                                    {secret}
                                  </option>
                                )
                              })}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <label htmlFor="filters" className="form-control-label">
                              Filtri (GSC)
                            </label>
                            <div id="filters">
                              {(filters?.length > 0) ? filters.map((group) => {
                                return (group.filters?.length > 0) ? 
                                  group.filters.map((filterParsed, index) => {
                                    return (
                                      <InputGroup key={index}>
                                        <Input
                                          id={`filters_${index}`}
                                          style={{
                                            padding: '0px 10px'
                                          }}
                                          placeholder="Filtro"
                                          value={`${filterParsed.dimension} ${filterParsed.operator} ${filterParsed.expression}`}
                                          disabled />
                                          <Button type="button" color="danger" onClick={() => deleteFilterIndex(index)}>X</Button>
                                      </InputGroup>
                                    )
                                  }) : null
                              }) : null }
                            </div>
                            <Button type="button" color="primary" onClick={() => setShowFiltersGSCModal(true)}>
                              Aggiungi Filtro
                            </Button>
                          </FormGroup>
                          
                          <FormGroup>
                            <label htmlFor="sitemap_indexes" className="form-control-label">
                              Urls sitemap index
                            </label>
                            <div id="sitemap_indexes">
                            {(siteurlSitemaps?.length > 0) ? siteurlSitemaps.map((sitemapUrl, index) => {
                              return (
                                <InputGroup key={index}>
                                  <Input
                                    id={`sitemap_indexes_${index}`}
                                    style={{
                                      padding: '0px 10px'
                                    }}
                                    placeholder="Url sitemap index"
                                    value={sitemapUrl.url}
                                    onChange = {event => {
                                      handleChange(event)
                                      const editedSitemaps = siteurlSitemaps
                                      editedSitemaps[index].url = event.target.value
                                      setSiteurlSitemaps(editedSitemaps)
                                    }} />
                                  <Button type="button" color="danger" onClick={() => deleteSitemapIndex(index)}>
                                    X
                                  </Button>
                                </InputGroup>
                              )
                            }): null}
                            </div>
                            <Button type="button" color="primary" onClick={() => addSitemapIndex()}>
                              Aggiungi URL
                            </Button>
                          </FormGroup>

                          <FormGroup>
                             <EmailContext.Provider value={{ emails, setEmails }}>
                               <Email context={EmailContext} />
                             </EmailContext.Provider>
                          </FormGroup>

                          <FormGroup style={{marginLeft: '1.2em'}}>
                            <label
                              htmlFor="active"
                              style={{ textTransform: 'none', fontWeight: '1' }}
                            >
                              <Input
                                className="form-control-alternative"
                                id={'active'}
                                type={'checkbox'}
                                style={{
                                  width: 'auto',
                                  display: 'inline',
                                  height: 'auto',
                                }}
                                checked={values.active}
                                onChange={event => {
                                  handleChange(event)
                                  setFieldValue('active', event.target.checked)
                                }}
                              />{' '}
                              Attivo
                            </label>
                            <FormText color="muted" style={{marginLeft:'-1.5em'}} align={'left'}>
                              Spunta questa casella per attivare o disattivare questo site.
                            </FormText>
                            {(errors.active) ? <FormText color="danger" align={'left'}> {errors.active} </FormText> : null}
                          </FormGroup>                          
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <label className="form-control-label">
                              Alert visibili in email
                            </label>
                            {alertEvents ? alertEvents?.map((aea) => (
                              <FormGroup key={'alert_events_active_'+aea.id} style={{ marginLeft: "18px"}}>
                                <Input
                                  className="form-control-alternative"
                                  id={'alert_events_active_'+aea.id}
                                  type="checkbox"
                                  style={{width: 'auto'}}
                                  checked={values.alert_events_active.filter(obj => obj.id == aea.id)[0] || false}
                                  onChange={event => {
                                    handleChange(event)
                                    if (event.target.checked) {
                                      setFieldValue('alert_events_active', [...values.alert_events_active, aea])
                                    }
                                    else {
                                      setFieldValue('alert_events_active', values.alert_events_active.filter(obj => obj.id != aea.id))
                                    }
                                  }}
                                />
                                <label htmlFor={'alert_events_active_'+aea.id} className="form-control-label">
                                  {aea?.code}
                                </label>
                                <FormText color="muted">{aea?.description}</FormText>
                              </FormGroup>
                            )) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{display: "flex", justifyContent: "flex-end", marginRight:"50px"}}>
                        <Button color="info" size="lg" type="submit" disabled={isSubmitting}>Salva</Button>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            )}
          </Formik>
        </Row>
      )}
      <ToastContainer />
    </>
  )
  
}

export default SiteUrlManageForm
