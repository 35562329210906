import React from 'react'
import VolumeRetrieveForm from './VolumeRetrieveForm'
import SetTitle from '../../utils/set_title'

export default function CreateVolumeRetrieve() {
	SetTitle('Market Screener')
	return (
        <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="fa-solid fa-chart-line fa-xl" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">Market Screener</h1>
				</div>
			</div>
			<VolumeRetrieveForm />
        </>
	)
}
