import React, { useState, useContext } from 'react'
import { Form, Col } from 'react-bootstrap'
import { useFetch } from '../../hooks'
import { FormSelectFilter } from '../../components/molecules'
import { WButton } from '../../components/atoms'
import { WSection } from '../../containers/WSection'
import { StudiKwFilterDataContext } from '../../HOC/studiKwFiltersContext'
import {
	Badge,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Pagination,
	PaginationItem,
	PaginationLink,
	Progress,
	Table,
	Container,
	Row,
	UncontrolledTooltip
} from "reactstrap";

const simpleResponse = ['si', 'no']

export const HomeFilterForm = () => {
	const [brand, setBrand] = useState('')

	const [country, setCountry] = useState('')

	const [industry, setIndustry] = useState('')

	const [mobile, setMobile] = useState('')

	const [multiplePositioning, setMultiplePositioning] = useState('')

	const brands = useFetch({ url: 'studiokw/brands/' })

	const countries = useFetch({ url: 'studiokw/countries/' })

	const industries = useFetch({ url: 'studiokw/industries/' })

	const [, , getFilteredData, , actualPage, setActualPage] = useContext(StudiKwFilterDataContext)

	const handleClearFilters = () => {
		setActualPage(1)
		setBrand('')
		setCountry('')
		setIndustry('')
		setMobile('')
		setMultiplePositioning('')
		getFilteredData({ page_size: 10, page: 1})
	}

	const handleApplyFilters = () => {
		setActualPage(1)
		let params = {
			...(brand.length > 0 && { brand: brand }),
			...(country.length > 0 && { country: country }),
			...(industry.length > 0 && { industry: industry }),
			...(mobile.length > 0 && { mobile: mobile === 'si' ? true : false }),
			...(multiplePositioning.length > 0 && {
				multiple_positioning: multiplePositioning === 'si' ? true : false,
			}),
			page_size: 10,
			page: 1,

		}
		getFilteredData(params)
	}

	return (
	<>
		{brands && countries && industries && (
		<div className="pb-8 pt-5 pt-md-8">
			<Row>
			<div className="col">
				<Card className="shadow">
					<CardHeader className="border-0">
						<h1 className="mb-0">Filters</h1>
					</CardHeader>
					<CardBody className="pt-0">
						<Form>
							<Row className="justify-content-center" style={{marginLeft:"80px", marginRight:"80px"}}>
								<Col>
									<FormSelectFilter
										label={'Brand'}
										value={brand}
										onChange={setBrand}
										data={brands.data}
									/>
								</Col>
								<Col>
									<FormSelectFilter
										label={'Country'}
										value={country}
										onChange={setCountry}
										data={countries.data}
									/>
								</Col>
								<Col>
									<FormSelectFilter
										label={'Industry'}
										value={industry}
										onChange={setIndustry}
										data={industries.data}
									/>
								</Col>
								<Col>
									<FormSelectFilter
										label={'Mobile'}
										value={mobile}
										onChange={setMobile}
										data={simpleResponse}
									/>
								</Col>
							</Row>
						</Form>
					<Row style={{display: "flex", justifyContent: "flex-end", marginRight: "92px"}}>
							<WButton label="Clear Filters" variant="light" onClick={handleClearFilters} />
							<WButton label="Apply" variant="primary" onClick={handleApplyFilters} />
					</Row>
					</CardBody>
					</Card>
					</div>
					</Row>
				</div>
			)}
		</>
	)
}
