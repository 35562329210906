/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import React from 'react';


const SchedulingHeader = () => {
  return (
    <>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-time-alarm text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">Scheduled Rank Inspector</h1>
				</div>
			</div>
    </>
  );
};

export default SchedulingHeader;
