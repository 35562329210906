import React, { useContext } from 'react'
import { FormGroup, FormText, Input } from 'reactstrap'

export default function AlertFlag({ context }) {
  const { alertFlag, setAlertFlag } = useContext(context)

  return (
    <FormGroup>
      <label htmlFor="alert-flag" className="form-control-label">
        Alert Flag
      </label>
      <div style={{ marginLeft: '+1.5em' }}>
        <Input
          className="form-control-alternative"
          type="checkbox"
          id="alert-flag"
          checked={alertFlag}
          style={{ width: 'auto' }}
          onChange={e => setAlertFlag(e.target.checked)}
        />
        <FormText color="muted" align="left">
          Spunta questa casella per attivare un alert prioritario nel caso il report non finisca nello stesso giorno di creazione.
        </FormText>
      </div>
    </FormGroup>
  )
}
