import React, { useContext, useState, useEffect, useRef } from 'react';
import { FormGroup, FormText } from 'reactstrap';
import { useParams } from 'react-router-dom';
import TagsInput from '../../components/TagsInput';
import { api } from '../../utils';

export default function Email({ context }) {
  const [allEmails, setAllEmails] = useState([]);
  const { emails, setEmails } = useContext(context);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const containerRef = useRef(null);
  let { id: studioKwID } = useParams();

  // Fetch emails from the backend (user table)
const fetchEmails = async () => {
  try {
    // Fetch the authenticated user's email
    const loginResponse = await api.get('api/1/email/');
    const loginEmail = loginResponse.data.email; // Ensure you access the `email` field properly.

    // Fetch all emails and group IDs
    const emailsResponse = await api.get('api/1/emails/');
    const allFetchedEmails = emailsResponse.data; // All tuples [(email, auth_group_id)]

    // Determine the authGroupId for the logged user
    const userTuple = allFetchedEmails.find(([email]) => email === loginEmail);
    const authGroupId = userTuple ? userTuple[1] : null;

    let filteredEmails;
    // Otherwise, show only emails in the same group
    filteredEmails = allFetchedEmails
      .filter(([, groupId]) => groupId === authGroupId)
      .map(([email]) => email); // Extract just emails

    setAllEmails(filteredEmails);
  } catch (error) {
    console.error('Error fetching emails:', error);
  }
};


  useEffect(() => {
    fetchEmails();
  }, []);

  // Check if the email is valid and not already selected
  const validateEmail = (email) => {
    if (!email) return false;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidFormat = re.test(email);
    const isDuplicate = emails
      .map((e) => e.toLowerCase())
      .includes(email.toLowerCase());

    if (!isValidFormat) {
      setError('Invalid email format');
      return false;
    } else if (isDuplicate) {
      setError('Email has already been added');
      return false;
    } else {
      setInputValue('')
      setError(null);
      return true;
    }
  };

  const addEmail = (email) => {
    if (validateEmail(email)) {
      setEmails([...emails, email]);
      setInputValue('');
      setError(null);
      setSuggestions([]);
    }
  };

  // Shows suggestions avoiding already selected emails
const handleInputChange = (e) => {
  const value = e.target.value.trim();
  setInputValue(value);
  setError(null);

  if (value) {
    const filteredSuggestions = allEmails
      .filter(
        (email) =>
          email.toLowerCase().includes(value.toLowerCase()) &&
          !emails.map((e) => e.toLowerCase()).includes(email.toLowerCase())
      )
      .map((email) => [email, null]);

    setSuggestions(filteredSuggestions);
  } else {
    setSuggestions([]);
  }
};

  // Suggestion list component
  const SuggestionList = ({ suggestions, onSuggestionClick }) => (
    <ul className="suggestions-list">
      {suggestions.map(([email, groupId], index) => (
        <li
          key={index}
          className="suggestion-item"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => onSuggestionClick(email)}
        >
          {email}
        </li>
      ))}
    </ul>
  );

  return (
    <FormGroup>
      <label htmlFor="report" className="form-control-label">
        Email
      </label>
      <div ref={containerRef}>
        <TagsInput
          className="form-control-alternative"
          id="email"
          selectedTags={setEmails}
          validator={validateEmail}
          tags={emails}
          type="text"
          onChange={handleInputChange}
          value={inputValue}
          onTagAdded={addEmail}
        />
        {error && <FormText color="danger">{error}</FormText>}
        <FormText color="muted">
          <b>Premere invio</b>  dopo ogni nuovo inserimento
        </FormText>

        {suggestions.length > 0 && (
          <SuggestionList
            suggestions={suggestions}
            onSuggestionClick={addEmail}
          />
        )}
      </div>
    </FormGroup>
  );
}
