import React from 'react'
import AlertEventTable from './AlertEventTable'
import SetTitle from '../../utils/set_title'

export default function AlertEvents() {
  SetTitle('SEO Sentinel Check')
	return (
    <>
      <div className="bg-gradient-info secondary-page-title" >
        <div md={12} className="form-title">
          <i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
          <h1 className="page-title">SEO Sentinel Check</h1>
        </div>
      </div>
      {/*<div className="page-description">*/}
      {/*  Riepilogo dei dati salvati su bigquery relativi al monitoraggio dei siti.*/}
      {/*</div>*/}
      <AlertEventTable />
    </>
	)
}