import React, { useContext, useEffect, useState, Fragment } from 'react'
import { api } from '../../utils/index'
import { Link, useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { useWindowSize } from '../../hooks'
import {
  Card,
  CardHeader,
  Button,
  Col,
  Table,
  Row,
} from "reactstrap"
import { volumeReportFilterDataContext } from '../../HOC/volumeReportFiltersContext'
import SetTitle from '../../utils/set_title'

function VolumeRetrieveListing() {
  SetTitle('Market Screener')
  const [volume_report_schedueld, setVolumeReportScheduled] = useState([])
  const [volume_report, setVolumeReport] = useState([])
  const [schedule_toggle, setScheduleToggle] = useState(true)
  const [pages, setPages] = useState(0)
  let history = useHistory()
  let windowSize = useWindowSize()
  const itemPerPage = 10

  const [filteredData, setFilteredData, getFilteredData, queryParams, actualPage, setActualPage] =
    useContext(volumeReportFilterDataContext)

  async function getData() {
    api.get('volume-report-scheduled/', {
      params: {
        page_size: 10,
        page: actualPage,
      },
    }).then((response) => {
      setScheduleToggle(true)
      setVolumeReportScheduled(response.data.results)
      setPages(Math.ceil(response.data.count / itemPerPage))
    })
  }

  function getVolumeReports(id) {
    api.get('volume-report/', {
      params: {
        id: id,
        page_size: 10,
        page: 1,
      },
    }).then((response) => {
      setScheduleToggle(false)
      setVolumeReport(response.data.results)
      setPages(Math.ceil(response.data.count / itemPerPage))
    })
  }

  const handlePageClick = (data) => {
    let path = schedule_toggle ? 'volume-report-scheduled/' : 'volume-report/'
    let currentPage = data.selected + 1
    setActualPage(currentPage)
    if (Object.keys(filteredData).length > 0) {
      getFilteredData({
        ...queryParams,
        page_size: 10,
        page: currentPage,
      })
    } else {
      api.get(path, {
        params: {
          page_size: 10,
          page: currentPage,
        },
      })
        .then((response) => {
          if (schedule_toggle) {
            setVolumeReportScheduled(response.data.results)
          } else {
            setVolumeReport(response.data.results)
          }
          setPages(Math.ceil(response.data.count / itemPerPage))
        })
        .catch((error) => console.error(error))
    }
  }

  useEffect(() => {
    if (filteredData.results) {
      setVolumeReportScheduled(filteredData.results)
      setPages(Math.ceil(filteredData.count / itemPerPage))
    } else {
      setPages(0)
      getData()
    }
  }, [filteredData])

  const icon = <i className="fa-solid fa-plus"></i>

  return (
    <>
      <Row style={{ marginBottom: "100px" }}>
        <div className="col pb-5">
					<Card className="shadow">
						<CardHeader className="border-0">
						<Row style={{ marginLeft: "0px" }}>
							<Col md={10}>
								<h1 className="mb-0">Market Screener</h1>
							</Col>
							{schedule_toggle ?
								(<Col md={2} style={{textAlign: "right"}}>
									<Button style={{marginRight: "55px"}} color="info" onClick={() => history.push('create-market-screener')} icon={icon}>+ Add new</Button>
									</Col>) :
								(<Col md={2} style={{textAlign: "right"}}>
									<Button style={{marginRight: "55px"}} color="warning" onClick={() => getData()} icon={icon}>&#60;- Go Back</Button>
								</Col>)}
						</Row>
						</CardHeader>
            <Table className="align-items-center table-flush" responsive>
              { !schedule_toggle ? (
                <>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ fontSize: "12px" }}>Name</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Status</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Date</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Only last month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {volume_report.map((item) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.status}</td>
                        <td>{item.date}</td>
                        <td>{item.only_last_month}</td>
                      </tr>
                    ))}
                  </tbody>
                </>
              ) : (
                <>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ fontSize: "12px" }}>Name</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Number of Runs</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Last Run</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Country</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Brand</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Bigquery table name</th>
                      <th scope="col" style={{ fontSize: "12px" }}>Market Screener Reports</th>
                      <th scope="col" style={{ fontSize: "12px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {volume_report_schedueld.map((item) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.number_of_runs}</td>
                        <td>{item.last_run}</td>
                        <td>{item.locale}</td>
                        <td>{item.status}</td>
                        <td>{item.bq_table_name}</td>
                        <td>
                          <Button
                            style={{ fontSize: windowSize.width <= 1280 ? "14px" : "16px" }}
                            onClick={() => getVolumeReports(item.id)}
                          >
                            Executions
                          </Button>
                        </td>
                        <td>
                          <Link to={`/market-screener/edit/${item.id}`}>
                            <Button
                              color="primary"
                              style={{ fontSize: windowSize.width <= 1280 ? "14px" : "16px" }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </Table>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={pages}
              forcePage={actualPage - 1}
              onPageChange={handlePageClick}
              containerClassName={'pagination justify-content-center pt-5 pb-3'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Card>
        </div>
      </Row>
    </>
  )
}

export default VolumeRetrieveListing
