import React, {useEffect, useState, Fragment, forwardRef} from 'react'
import {Card, Button, UncontrolledTooltip} from 'reactstrap'
import DetailFilter from './DetailFilter'
import _ from 'lodash'
import DetailSeoRow from './DetailSeoRow'
import {prefix, loginRedirect} from '../../../utils/index_tagging_tool'
import {api} from '../../../utils/index'
import {faCog, faSort, faTimes, faSearch} from '@fortawesome/fontawesome-free-solid'
import {FixedSizeList as List} from 'react-window'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {OperationsManager} from './DetailSeoOperations/OperationsManager'
import {LanguageShow} from './DetailSeoLanguage/LanguageShow'
import {LanguageName} from './DetailSeoLanguage/LanguageName'
import {LanguageSearch} from './DetailSeoLanguage/LanguageSearch'
import BulkExcelUpload from '../DetailUtility/BulkExcelUpload'
import DetailRuleList from "./DetailRuleList";
import ExcelDownload from "../DetailUtility/ExcelDownload";
import Loading from "../../components/Loading";

const TableContext = React.createContext()
const ratio = {
	regex: '300px',
	tag: '200px',
	dependency: '150px',
	priority: '100px',
	checkbox: '100px'
}

// Selettore delle "altre dimensioni" per decidere quali mostrare o meno. E' un blocchetto con una lista di checkbox per dire "questa si/questa no"
export function DimensionSelector({
	                                  otherDimensions,
	                                  dimensionsFilter,
	                                  setDimensionsFilter,
	                                  showDimensionSelector,
	                                  setShowDimensionSelector,
                                  }) {
	const handleClick = (e) => {
		// Se checkato lo aggiungo
		if (e.target.checked) {
			dimensionsFilter.push(e.target.value)
			dimensionsFilter = _.sortBy(dimensionsFilter, 'display_name')
			setDimensionsFilter(dimensionsFilter)
			return
		}
		// Se non checkato lo tolgo
		const index = dimensionsFilter.indexOf(e.target.value)
		dimensionsFilter.splice(index, 1)
		dimensionsFilter = _.sortBy(dimensionsFilter, 'display_name')
		setDimensionsFilter(dimensionsFilter)
	}

	return (
		<div
			className="overlay"
			style={{display: showDimensionSelector ? 'block' : 'none'}}
		>
			<div className="tiny-modal">
				<button
					type="button"
					className="tiny-modal__close"
					onClick={() => setShowDimensionSelector(false)}
				>
					<FontAwesomeIcon icon={faTimes}/>
				</button>
				<form className={'dimension-selector'}>
					{otherDimensions.map((d, i) => (
						<label className={'dimension-selector-label'}>
							<input
								type="checkbox"
								name={'dim' + i}
								onClick={handleClick}
								checked={dimensionsFilter.includes(d.display_name)}
								value={d.display_name}
							/>{' '}
							{d.display_name}
						</label>
					))}
				</form>
			</div>
		</div>
	)
}

// Componente con un tastino epr cambiare la pagina momentaneamente visualizzata
export function PageNavigator({setPage, pages, active}) {
	return (
		<>
			{[...Array(pages).keys()].map((i) => (
				<button
					className={
						'w-seo-page-button' + (active === i + 1 ? ' active-page' : '')
					}
					type="button"
					onClick={(e) => {
						e.preventDefault()
						setPage(i + 1)
					}}
				>
					{i + 1}
				</button>
			))}
		</>
	)
}

// Componente padre della riga dei nomi delle colonne, a cui si aggiunge eventualmente una lista di blocchi delle lingue
function TableNames() {
	const {
		currentOrder,
		handleOrder,
		showDimensionSelector,
		setShowDimensionSelector,
		otherDimensions,
		dimensionsFilter,
		setDimensionsFilter,
	} = React.useContext(TableContext)

	return (
		<>
			<div style={{width: ratio.priority}} id='priority-title' className="flex">
				<h3>
					PR{' '}
					<FontAwesomeIcon
						className={
							currentOrder.field === 'priority'
								? 'selected-order'
								: 'not-selected-order'
						}
						style={{cursor: 'pointer'}}
						icon={faSort}
						onClick={() => handleOrder('priority')}
					/>
				</h3>
				<UncontrolledTooltip target='priority-title' placement='top'>
					Priorità delle regole
				</UncontrolledTooltip>
			</div>
			<LanguageName context={TableContext}/>
			<div style={{width: ratio.tag}} className="text-left">
				<h3>
					TAG
					<FontAwesomeIcon
						className={
							currentOrder.field === 'rule.value'
								? 'selected-order'
								: 'not-selected-order'
						}
						style={{cursor: 'pointer'}}
						icon={faSort}
						onClick={() => handleOrder('rule.value')}
					/>
				</h3>
			</div>
			<div style={{width: ratio.regex}} className="text-left" id="contains-title">
				<h3>CONTIENE</h3>
				<UncontrolledTooltip target='contains-title' placement='top'>
					Se le regex è verificata, il tag viene applicato
				</UncontrolledTooltip>
			</div>
			<div style={{width: ratio.regex}} className="text-left" id="not-contains-title">
				<h3>NON CONTIENE</h3>
				<UncontrolledTooltip target='not-contains-title' placement='top'>
					Se le regex è verificata, il tag <u>non</u> viene applicato
				</UncontrolledTooltip>
			</div>
			<div style={{width: ratio.dependency}} className="text-left" id="dependency-title">
				<h3>
					ALTRE DIMENSIONI{' '}
					<FontAwesomeIcon
						style={{cursor: 'pointer'}}
						icon={faCog}
						onClick={() => setShowDimensionSelector(!showDimensionSelector)}
					/>
				</h3>
				<DimensionSelector
					showDimensionSelector={showDimensionSelector}
					otherDimensions={otherDimensions}
					dimensionsFilter={dimensionsFilter}
					setDimensionsFilter={setDimensionsFilter}
					setShowDimensionSelector={setShowDimensionSelector}
				/>
				<UncontrolledTooltip target='dependency-title' placement='top'>
					Dimensioni con regole dipendenti
				</UncontrolledTooltip>
			</div>
		</>
	)
}

// Componente padre della riga dei textbox per la ricerca, a cui si aggiunge eventualmente una lista di blocchi delle lingue
function TableSearch() {
	const {
		valueFilter,
		setValueFilter,
		contieneFilter,
		setContieneFilter,
		noncontieneFilter,
		setNoncontieneFilter,
		otherDimensionsFiltered,
		setShouldSearch,
	} = React.useContext(TableContext)

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			setShouldSearch(true);
		}
	}

	return (
		<>
			<LanguageSearch context={TableContext}/>
			<div style={{width: ratio.tag}}>
				<DetailFilter filter={valueFilter} setFilter={setValueFilter} handleKeyDown={handleKeyDown}/>
			</div>
			<div style={{width: ratio.regex}}>
				<DetailFilter filter={contieneFilter} setFilter={setContieneFilter} handleKeyDown={handleKeyDown}/>
			</div>
			<div style={{width: ratio.regex}}>
				<DetailFilter
					filter={noncontieneFilter}
					setFilter={setNoncontieneFilter}
					handleKeyDown={handleKeyDown}
				/>
			</div>
			{otherDimensionsFiltered.map((d) => (
				<div className="w-seo-control-field text-center" style={{width: ratio.dependency}}>{d.display_name}</div>
			))}
		</>
	)
}

// copiato da documentazione di react table
const innerElementType = forwardRef(({style, ...rest}, ref) => {
	/* const {
		valueFilter,
		setValueFilter,
		contieneFilter,
		setContieneFilter,
		noncontieneFilter,
		setNoncontieneFilter,
		otherDimensionsFiltered,
	} = React.useContext(ListContext); */

	return (
		<Fragment>
			<div
				ref={ref}
				style={{
					...style,
					heigth: `${parseFloat(style.height)}px`,
				}}
				{...rest}
			/>
		</Fragment>
	)
})

// Macrocomponente che comprende la tabella seo per la modifica delle regole, e i vari bottoni per il salvataggio/aggiunta regole
export default function DetailSeoForm({
	                                      values,
	                                      loader,
	                                      fetchRules,
	                                      pages,
	                                      removeRule,
	                                      handleRule,
	                                      detailId,
	                                      detailLinkedId,
	                                      detailReport,
	                                      detailName,
	                                      detailType,
	                                      otherDimensions,
	                                      reorderRules,
	                                      showRegexModal,
	                                      listRef,
                                      }) {
	// Paginazione, che può essere presente quando ci sono più di X regole manual che arrivano da backend (> X rompono google chrome intasando completamente la RAM)
	const [page, setPage] = useState(1)
	const [lastFetched, setLastFetched] = useState(1)

	// Filtri per regole
	const [valueFilter, setValueFilter] = useState('')
	const [contieneFilter, setContieneFilter] = useState('')
	const [noncontieneFilter, setNoncontieneFilter] = useState('')
	// Mostro regole che rispettano il filtro (se c'è), + le regole nuove non ancora salvate
	const manualRules = values.rules.filter(
		(rule) =>
			rule.id === -1 ||
			((contieneFilter === '' ||
					(rule.rule.conditions.length > 0 &&
						rule.rule.conditions[0]['operand'])) &&
				(noncontieneFilter === '' ||
					(rule.rule.conditions.length > 1 &&
						rule.rule.conditions[1]['operand'])))
	)

	// Variabili per decidere quali colonne di altre dimensioni del report/gruppo mostrare
	const [dimensionsFilter, setDimensionsFilter] = useState([])
	const [showDimensionSelector, setShowDimensionSelector] = useState(false)
	const otherDimensionsFiltered = otherDimensions.filter(
		(d) =>
			dimensionsFilter.includes(d.display_name) ||
			dimensionsFilter.includes(d.display_name.split(' '))
	)

	// Mantiene le regole disabilitate (aka non modificabili e non applicate in fase di run/simula)
	const [disabledRules, setDisabledRules] = useState([])
	const [thanosRules, setThanosRules] = useState([])

	const adaptName = (name, reportName) => {
		// Non bellissimo, è dovuto al fatto che il serializer mi ritorna il nome della dimensione con (Gruppo) per le saved dimension
		// (mentre il campo della regola ha il nome normale)
		var elaborated_name = name.split(' (')[0].replace(` ${reportName}`, '')
		// Anche gli accenti danno problemi
		elaborated_name = elaborated_name
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
		return elaborated_name
	}

	useEffect(() => {
		// Se non faccio così il dimensionsFilter si setta a [] al primo rendering e anche quando arriva
		// l'array non si aggiorna
		otherDimensions.length > 0 &&
		setDimensionsFilter(
			otherDimensions
				.filter(
					(d) =>
						d.rules
							// Prendo solo le dipendenti
							.filter((r) => r.type === 'dependency')
							// Che hanno come operando il valore di questa regola, e come campo il nome di questa dimensione
							.filter(
								(r) =>
									r.rule.conditions.filter(
										(x) => x.field === adaptName(detailName, detailReport)
									).length > 0
							).length > 0
				)
				.map((d) => d.display_name)
		)
	}, [detailName, detailReport, otherDimensions])

	// Variabili e funzione per ordinare le colonne, in ordine alfabetico crescente/decrescente (o numerico per la priority). Per ora disponibile solo per PR e NOME
	const [currentOrder, setCurrentOrder] = useState({
		field: 'priority',
		order: 'asc',
	})

	const handleOrder = (field) => {
		if (currentOrder.field === field) {
			currentOrder.order = currentOrder.order === 'asc' ? 'desc' : 'asc'
		} else {
			currentOrder.field = field
			currentOrder.order = 'asc'
		}
		reorderRules(currentOrder)
		setCurrentOrder(currentOrder)
	}

	// Recupera le regole disabilitate da backend
	const getDisabledThanos = () => {
		api.get(prefix + `disabled-rules/?custom=${detailId}`)
			.then(loginRedirect)
			.then((data) => setDisabledRules(data.map((dr) => dr.disabled)))

		api.get(prefix + `thanos-rules/?custom=${detailId}`)
			.then(loginRedirect)
			.then((data) => setThanosRules(data.map((th) => th.thanos)))
	}

	useEffect(() => {
		// Al primo caricamento setto l'ordinamento di default
		reorderRules(currentOrder)
		// E recupero la lista di regole disabilitate di questa dimensione (se è custom)
		detailType === 'custom-dimensions' && getDisabledThanos()
	}, [])

	// Checkbox delle regole
	const [checked, setChecked] = useState([])

	// Funzioni di comodo per riempire/svuotare le checkbox delle regole
	const fillChecked = () => {
		setChecked(values.rules.map((r) => r.id).filter((id) => id !== -1))
	}

	const emptyChecked = () => {
		setChecked([])
	}

	// Lingue
	const [showLanguages, setShowLanguages] = useState(false)
	const [selectedLanguages, setSelectedLanguages] = useState(['Inglese'])
	const [collapse, setCollapse] = useState(true)
	const [search_, setSearch_] = useState(false)
	const languageOptions = [
		'Inglese',
		'Italiano',
		'Francese',
		'Spagnolo',
		'Tedesco',
	]

	// Calcola la larghezza della tabella in base alle lingue (se mostrate) e altre dimensioni selezionate
	const listWidth =
		1400 +
		Math.max(otherDimensionsFiltered.length - 2, 0) * 150 + // le prime due dimensioni sono già comprese nei 1300
		(showLanguages ? selectedLanguages.length * 200 : 0)

	const [shouldSearch, setShouldSearch] = useState(false);

	const handleSearchClick = () => {
		console.log('Pulsante Cerca cliccato'); // Aggiungi un log per confermare il click
		console.log(shouldSearch);
		setShouldSearch(true);
		console.log(shouldSearch);
	};

	// Paginazione
	useEffect(() => {
	if (valueFilter !== '' || contieneFilter !== '' || noncontieneFilter !== '') {
		// Cerca tra le regole via API solo se ci sono almeno 3 lettere
		let queryParams = {}

		queryParams['query'] = valueFilter

		queryParams['queryContiene'] = contieneFilter

		queryParams['queryNonContiene'] = noncontieneFilter

		if (Object.keys(queryParams).length > 0 && shouldSearch) {
			fetchRules(null, 1, queryParams)
			setLastFetched(-1)
			setShouldSearch(false);
		}
	} else {
		// Evita che vengano ricaricate le regole se per qualche motivo si riseleziona la stessa pagina
		if (lastFetched !== page) {
			fetchRules(null, page)
			setLastFetched(page)
			setShouldSearch(false);
		}
	}
}, [shouldSearch, page, valueFilter, contieneFilter, noncontieneFilter])

	return (
		<Fragment>
			<DetailRuleList values={values}/>
			<Card className="border-1 ml-4 mr-4 bg-secondary">
				<TableContext.Provider
					value={{
						valueFilter: valueFilter,
						setValueFilter: setValueFilter,
						contieneFilter: contieneFilter,
						setContieneFilter: setContieneFilter,
						noncontieneFilter: noncontieneFilter,
						setNoncontieneFilter: setNoncontieneFilter,
						otherDimensions: otherDimensions,
						otherDimensionsFiltered: otherDimensionsFiltered,
						currentOrder: currentOrder,
						handleOrder: handleOrder,
						showDimensionSelector: showDimensionSelector,
						setShowDimensionSelector: setShowDimensionSelector,
						dimensionsFilter: dimensionsFilter,
						setDimensionsFilter: setDimensionsFilter,
						disabledRules: disabledRules,
						setDisabledRules: setDisabledRules,
						thanosRules: thanosRules,
						setThanosRules: setThanosRules,
						checked: checked,
						setChecked: setChecked,
						fillChecked: fillChecked,
						emptyChecked: emptyChecked,
						rules: values.rules,
						removeRule: removeRule,
						dimension: detailId,
						dimensionType: detailType,
						linked: detailLinkedId,
						showLanguages: showLanguages,
						setShowLanguages: setShowLanguages,
						selectedLanguages: selectedLanguages,
						setSelectedLanguages: setSelectedLanguages,
						languageOptions: languageOptions,
						setShouldSearch: setShouldSearch,
					}}
				>
					{pages > 1 && (
						<div className={'mb-3'}>
							<PageNavigator pages={pages} setPage={setPage} active={page}/>
						</div>
					)}
					{}
					<div style={{position: 'relative'}}>
						{/* riferimento per popup lingue - fuori dall'overflow */}
						<div style={{overflowX: 'auto'}}>
							<div className={'text-center'}>
								<div className="d-flex align-items-center mb-2" style={{width: listWidth}}>
									<LanguageShow context={TableContext}/>
									<TableNames/>
								</div>
								<div className="d-flex align-items-center" style={{width: listWidth}}>
									<OperationsManager context={TableContext}/>
									<TableSearch/>
								</div>
								{(values?.rules?.length > 0 && otherDimensions.length > 0) && !loader ? (
									<List
										className="List"
										style={{overflowY: 'scroll'}}
										// 32 è la riga delle label delle dimensioni
										ref={listRef}
										height={Math.min(manualRules.length * 47, 690)}
										innerElementType={innerElementType}
										itemCount={manualRules.length}
										itemSize={47}
										width={listWidth}
									>
										{(props) =>
											DetailSeoRow({
												...props,
												dimension: detailId,
												linkedDimension: detailLinkedId,
												dimensionType: detailType,
												dimensionReport: detailReport,
												rules: values.rules,
												// Tolgo 1 che è la prima riga degli headers
												rule: manualRules[props.index],
												ruleIndex: manualRules[props.index].index,
												removeRule: removeRule,
												otherDimensions: otherDimensionsFiltered,
												disabledRules: disabledRules,
												setDisabledRules: setDisabledRules,
												thanosRules: thanosRules,
												setThanosRules: setThanosRules,
												checked: checked,
												setChecked: setChecked,
												showLanguages: showLanguages,
												selectedLanguages: selectedLanguages,
												ratio: ratio
											})
										}
									</List>
								) : <Loading/>}
							</div>
						</div>
					</div>
				</TableContext.Provider>
			</Card>

			<div className={'d-flex justify-content-between ml-4 mr-4 mt-2 mb-2'}>
				<div className={'float-start'}>
					<Button id="regex101" color="link"
					        disabled={!(values?.rules?.length > 0 && otherDimensions.length > 0) || loader}>
						<a href="https://regex101.com/" target="_blank" rel="noopener noreferrer">Regex101</a>
					</Button>
					<UncontrolledTooltip
						placement="right"
						target="regex101"
					>
						Testa qui la tua regex
					</UncontrolledTooltip>
				</div>

				<div className={'float-end'}>
					{detailType === 'custom-dimensions' && (
						<>
							<ExcelDownload dim={detailId}
							               loader={!(values?.rules?.length > 0 && otherDimensions.length > 0) || loader}
														 mode={"custom"}/>
							<BulkExcelUpload dim={detailId}
							                 loader={!(values?.rules?.length > 0 && otherDimensions.length > 0) || loader}/>
						</>
					)}
					<button
						type="button"
						className="btn btn-info mr-2"
						onClick={(e) => showRegexModal()}
						disabled={!(values?.rules?.length > 0 && otherDimensions.length > 0) || loader}
					>
						Costruisci regex
					</button>
					{
						// Mostrato solo nelle dimensioni custom
						detailType === 'custom-dimensions' && (
							<button
								type="button"
								className="btn btn-warning mr-2"
								onClick={(e) => handleRule(e, values)}
								disabled={!(values?.rules?.length > 0 && otherDimensions.length > 0) || loader}
							>
								Aggiungi Regola
							</button>
						)
					}
					{
						// Mostrato solo nelle dimensioni custom
						(detailType === 'saved-dimensions' || detailLinkedId) && (
							<button
								type="button"
								className="btn btn-warning"
								onClick={(e) => handleRule(e, values, 'saved')}
								disabled={!(values?.rules?.length > 0 && otherDimensions.length > 0) || loader}
							>
								Aggiungi Regola Salvata
							</button>
						)
					}
				</div>
			</div>
		</Fragment>
	)
}
