import React from 'react'
import { HomeFilterForm } from '../containers/HomeFiltersForm'
import { WLinkButton } from '../components/molecules'
import { WSection } from '../containers/WSection'
import StudiKWListing from '../containers/StudiKwListing'
import { FilterDataProvider } from '../HOC/studiKwFiltersContext'
import { BASE_URL } from '../utils/index'
import SetTitle from '../utils/set_title'

const Scheduling = () => {
  SetTitle('Scheduled Rank Inspector')

  return (
    <FilterDataProvider  path="studiokw-scheduled/" >
        <div className="bg-gradient-info main-page-title" >
            <div md={12} className="form-title">
              <i className="ni ni-time-alarm text-white" style={{color: "white", fontSize: "35px"}}></i>
              <h1 className="page-title">
                Scheduled Rank Inspector
                <a href={BASE_URL + '/docs/ranking_tools/scheduling/'} target="_blank" style={{paddingLeft: "10px"}}>
                  <i className="fa-solid fa-question-circle fa-xs text-white"></i>
                </a>
              </h1>
            </div>
          <HomeFilterForm />
        </div>
      <StudiKWListing page="scheduling"/>
  </FilterDataProvider>
  )
}

export default Scheduling
