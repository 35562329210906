import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  prefix,
  loginRedirect,
  notify,
} from '../../../utils/index_tagging_tool'

import { api } from '../../../utils/index'

import { saveAs } from 'file-saver'

export default function ExcelDownload({ dim, loader, mode }) {
  const [loading, setLoading] = useState(false)

  const handleExport = async (e) => {
    e.preventDefault()

    // Prevent starting a new export if one is already running.
    if (loading) {
      notify("L'export è già in corso. Attendere il completamento.", 'warning')
      return
    }

    setLoading(true)
      api(prefix + `${mode}-rule-file-download/${dim}/`, { responseType: 'blob' })
      .then((res) => {
      if (res.status === 202) {
        notify("Iniziata la generazione dell'export. Riceverai una mail quando sarà pronto.", 'success')
      } else if (res.status < 300 && res.status >= 200) {
        saveAs(res.data, `${dim}_export.xlsx`)
        notify('File generato con successo', 'success')
      }
      }).catch((err) => {
      if (err.response) {
        if (err.response.status === 404) {
          notify("Nessuna dimensione trovata per l'export", 'error');
        } else if (err.response.status < 500) {
          notify(err.response.data, 'error');
        } else {
          notify('Errore del server', 'error');
        }
      } else {
        notify('Errore del server', 'error');
      }
    })
    .finally(() => setLoading(false));
};

  return (
    <>
      {loading ? (
         <div className="spinner-border mr-3" role="status">
           <span className="sr-only">Loading...</span>
         </div>
      ) : (
        <Button
          onClick={(e) => handleExport(e)}
          variant="warning"
          className={'btn btn-info mr-2'}
          disabled={loading || loader}
        >
          Esporta
        </Button>
      )}
    </>
  )
}