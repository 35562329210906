import React from 'react'
import VolumeRetrieveListing from './VolumeRetrieveListing'
import VolumeFilterForm from './VolumeFilterForm'
import { FilterDataProvider } from '../../HOC/volumeReportFiltersContext'
import { BASE_URL } from '../../utils/index'
import SetTitle from '../../utils/set_title'

export default function VolumeRetrieve() {
	SetTitle('Market Screener')
	return (
		<FilterDataProvider>
        	<div className="bg-gradient-info main-page-title" >
				<div md={12} className="form-title">
					<i className="fa-solid fa-chart-line fa-xl" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						Market Screener
						<a href={BASE_URL + '/docs/ranking_tools/market-screener/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>
					</h1>
				</div>
				< VolumeFilterForm />
			</div>
			<VolumeRetrieveListing />
		</FilterDataProvider>
	)
}
