import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  UncontrolledDropdown,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  // closes the collapse
  const closeCollapse = () => setCollapseOpen(false);
  // Filter routes based on whether the route is public or allowed
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      // Check if the route is allowed. If the route is not marked as private, show it.
      // Otherwise, see if the allowedServices array includes a service with a matching route.
      const isAllowed =
        !prop.private ||
        (props.allowedServices &&
          props.allowedServices.some(
            (service) => service.route === prop.path || service.route === prop.layout + prop.path
          ));
      return isAllowed ? (
        <div key={key}>
          <NavItem>
            <NavLink
              {...(prop.path.includes("http://") || prop.path.includes("https://")
                ? { to: { pathname: prop.path }, target: "_blank" }
                : { to: prop.layout + prop.path })}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
          {prop.name === "Segnalazione" && <hr className="my-3" />}
        </div>
      ) : null;
    });
  };

  const createLinks_reporting = (routes) => {
    return routes.map((prop, key) => (
      <NavItem key={key} style={{ marginLeft: "40px", marginBottom: "-15px" }}>
        {prop.name === "Guida" ? (
          <NavLink href={prop.path} onClick={closeCollapse} activeClassName="active">
            {prop.name}
          </NavLink>
        ) : (
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            {prop.name}
          </NavLink>
        )}
      </NavItem>
    ));
  };

  const { bgColor, routes, logo, onLogout, allowedServices } = props;
  const reporting_routes = routes.filter((route) => route.path === "reporting")[0].childrens;

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = { to: logo.innerLink, tag: Link };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = { href: logo.outterLink, target: "_blank" };
  }

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <button className="navbar-toggler" type="button" onClick={() => setCollapseOpen(!collapseOpen)}>
        <span className="navbar-toggler-icon" />
      </button>
      {logo && (
        <NavbarBrand className="pt-4" {...navbarBrandProps}>
          <img width="80%" height="80%" alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
        </NavbarBrand>
      )}
      <Collapse navbar isOpen={collapseOpen}>
        <div className="navbar-collapse-header d-md-none">
          <Row>
            {logo && (
              <Col className="collapse-brand" xs="6">
                {logo.innerLink ? (
                  <Link to={logo.innerLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </Link>
                ) : (
                  <a href={logo.outterLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </a>
                )}
              </Col>
            )}
            <Col className="collapse-close" xs="6">
              <button className="navbar-toggler" type="button" onClick={closeCollapse}>
                <span />
                <span />
              </button>
            </Col>
          </Row>
        </div>
        <Form className="mt-4 mb-3 d-md-none">
          <InputGroup className="input-group-rounded input-group-merge">
            <Input aria-label="Search" className="form-control-rounded form-control-prepended" placeholder="Search" type="search" />
          </InputGroup>
        </Form>
        <Nav navbar>{createLinks(routes)}</Nav>
        {window.location.pathname.includes("/reporting") && (
          <Nav navbar>{createLinks_reporting(reporting_routes)}</Nav>
        )}
        <Nav className="mb-md-3" navbar>
          <NavItem className="logout-button">
            <Button onClick={onLogout} outline color="info">
              Logout
            </Button>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
  allowedServices: [], // default empty array if not passed
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  allowedServices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
  onLogout: PropTypes.func,
};

export default Sidebar;
