import React from 'react'
import {Accordion, Card, Button, Badge} from 'react-bootstrap'
import GroupCardBody from "./GroupCardBody";
import ExcelDownload from '../DetailUtility/ExcelDownload'
import { FALSE, TRUE } from 'sass'

// Questo era qui da prima di me, se avete rimostranze mi dispiace, le avevo anch'io
export default function DimensionGroup(props) {
    const group = props.group
    const key = group.name
    const dimensions = props.savedDimensions
    const reloadListing = props.reloadListing

    return (
      <Accordion defaultActiveKey="0">
      <Card key={key}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Accordion.Toggle as={Button} variant="link" eventKey={key} className="shadow-none">
            {group.name}{' '}
            {group.is_super ? (
               <Badge pill variant="success">
                 SUPER
               </Badge>
            ) : ('')}
          </Accordion.Toggle>
          <ExcelDownload dim={group.name}
           loader={false} // there's no need to wait for a load here
           mode={"saved"}/>
        </Card.Header>

        <Accordion.Collapse eventKey={key} key={key}>
          <GroupCardBody group={group} dimensions={dimensions} key={key} reloadListing={reloadListing}/>
        </Accordion.Collapse>
      </Card>
      </Accordion>
    )
}
