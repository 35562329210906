import React, { useContext, useEffect, useState, Fragment } from 'react'
import { api } from '../../utils/index'
import BootstrapTable from 'react-bootstrap-table-next'
import { useHistory } from 'react-router-dom'
import {Form, FormText, Spinner } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ReactPaginate from 'react-paginate'
import { useWindowSize } from '../../hooks'
import { Link, useParams } from 'react-router-dom'
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Button,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import math, { all, create } from 'mathjs'

function ScheduledStudiokwDetailList() {
  const [related_studikw, setRelatedStudikw] = useState([])
  const [pages, setPages] = useState(0)
  let history = useHistory()
  let windowSize = useWindowSize()
  const itemPerPage = 10

  // url param
  let { id: studioKwID } = useParams()

  const math = create(all)

  const getData = async(id) => {
    await api
      .get(`studiokw/`,{
        params: {
          id: id,
          page_size: 10,
          page: 1,

        },
      })
      .then((response) => {
        setRelatedStudikw(response.data.results)
        setPages(Math.ceil(response.data.count / itemPerPage))
      })
      .catch((error) => console.error(error))
  }


  const handlePageClick = (data) => {
    let path = 'studiokw/'
    let currentPage = data.selected + 1
    api
      .get(path, {
        params: {
          id: studioKwID,
          page_size: 10,
          page: currentPage,

        },
      })
      .then((response) => {
        setRelatedStudikw(response.data.results)
        setPages(Math.ceil(response.data.count / itemPerPage))
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    getData(studioKwID)
  }, [])


  return (
    <>
      <Row style={{marginBottom: "100px"}}>
        <div className="col pb-5">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row style={{ marginLeft: "0px" }}>
                <Col md={10}>
                  <h1 className="mb-0">Studi Keywords</h1>
                </Col>
                <Col md={2} style={{textAlign: "right"}}>
                  <Button color="warning" onClick={() => history.push('/scheduling')}>&#60;- Go Back</Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <>
                <thead className="thead-light">
                <tr>
                  <th scope="col" style={{fontSize: "12px"}}>Name</th>
                  <th scope="col" style={{fontSize: "12px"}}>Status</th>
                  <th scope="col" style={{fontSize: "12px"}}>Date</th>
                  <th scope="col" style={{fontSize: "12px"}}>Progress</th>
                </tr>
                </thead>
                <tbody>
                {related_studikw.map((item) => (
                  <tr>
                    <td>{item.nome}</td>
                    <td>{item.status}</td>
                    <td>{item.created}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>
                          <Progress
                            max="100"
                            value={(math.evaluate(item.progress) * 100).toString()}
                            barClassName={
                              math.evaluate(item.progress) === 1 ? 'bg-success' : 'bg-warning'
                            }
                          />
                          {item.progress}
                        </div>
                      </div>
                    </td>

                  </tr>
                ))}
                </tbody>
              </>
            </Table>

          </Card>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={pages}
            onPageChange={handlePageClick}
            containerClassName={'pagination justify-content-center pt-5 pb-3'} // classname of the pagination container (ul)
            pageClassName={'page-item'} // classname on tag li of each page element
            pageLinkClassName={'page-link'} // classname on tag a of each page element
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </Row>
    </>
  )
}
export default ScheduledStudiokwDetailList