import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { api } from '../../utils/index'
import SetTitle from '../../utils/set_title'
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
  FormText, Badge,
} from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { notify } from '../../utils/notify'

function VolumeRetrieveEditForm() {
  SetTitle('Market Screener')

  const { id } = useParams()
  const history = useHistory()

  // Record data (read-only except Monthly Update)
  const [volumeRetrieve, setVolumeRetrieve] = useState({
    name: '',
    schedule: false,
    locale: '',
    province: '',
    email: '',
    bq_table_name: '',
    brand: ''
  })

  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)

  // Fetch record on report load
  useEffect(() => {
    api
      .get(`/market-screener-detail/${id}`)
      .then(response => {
        setVolumeRetrieve(response.data)
        setLoading(false)
      })
      .catch(err => {
        setError('Error fetching record.')
        setLoading(false)
      })
  }, [id])

  // Handle file change
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setSaving(true)

    const formData = new FormData()

    // Append CSV file if selected
    if (file) {
      formData.append('file', file)
    }

    // Append all other fields (read-only but sent for completeness)
    formData.append('name', volumeRetrieve.name)
    formData.append('schedule', volumeRetrieve.schedule)
    formData.append('locale', volumeRetrieve.locale)
    formData.append('province', volumeRetrieve.province)
    formData.append('email', volumeRetrieve.email)
    formData.append('bq_table_name', volumeRetrieve.bq_table_name)
    formData.append('brand', volumeRetrieve.brand)

    api
      .put(`/market-screener-detail/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        notify('Keywords Aggiornate Correttamente', 'success')
        setTimeout(() => {
          history.push('/market-screener');
        }, 3000); // 3-seconds delay

      })
      .catch(err => {
        notify('Errore nel salvataggio', 'error')
        setSaving(false)
      })
  }

if (loading) {
  return (
    <div className="spinner-container">
      <div className="spinner-dot"></div>
      <div className="spinner-dot"></div>
      <div className="spinner-dot"></div>
    </div>
  )
}


  if (error) {
    return (
      <div className="text-center text-danger" style={{ marginTop: '20px' }}>
        {error}
      </div>
    )
  }

return (
  <>
    <div className="pb-5">
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row style={{ marginLeft: "0px" }}>
            <Col md={10}>
              <h1 className="mb-0">Market Screener</h1>
            </Col>
            <Col md={2} style={{ textAlign: "right" }}>
              <Button color="warning" onClick={() => history.push('/market-screener')}>
                &lt;- Go Back
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit} id="market-screener-form">
            {/* --- Row 1: File Input --- */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="file" style={{ display: "block" }}>
                    File
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="file"
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv, .xlsx"
                  />
                  <FormText color="muted" align="left">
                    Carica un file contenente le tue keywords. <br />
                    <b>Il file deve contenere almeno una colonna "Keyword"</b>
                    <br />
                    <b>
                      Le singole keyword non devono contenere questi simboli: @ \ ^ = ! ` {'<'} {'>'} {'['} {']'} {'('} {')'} % | ? ; ~
                    </b>
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <br />

            {/* --- Row 2: Nome & Email --- */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="nome" style={{ display: "block" }}>
                    Nome
                  </Label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    name="nome"
                    id="nome"
                    value={volumeRetrieve.name}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email" style={{ display: "block" }}>
                    Email
                  </Label>
                  <Input
                    className="form-control-alternative"
                    type="email"
                    name="email"
                    id="email"
                    value={volumeRetrieve.email}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* --- Row 3: Location & Province --- */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="location" style={{ display: "block" }}>
                    Location
                  </Label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    name="location"
                    id="location"
                    value={volumeRetrieve.locale}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="province" style={{ display: "block" }}>
                    Province
                  </Label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    name="province"
                    id="province"
                    value={volumeRetrieve.province}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* --- Row 4: Monthly Update Switch (editable) --- */}
            <Row className="mb-3">
              <Col md={6}>
                <FormGroup className="d-flex align-items-center gap-2">
                  <Input
                    type="switch"
                    id="schedule"
                    checked={volumeRetrieve.schedule}
                    onChange={(e) =>
                      setVolumeRetrieve({
                        ...volumeRetrieve,
                        schedule: e.target.checked
                      })
                    }
                  />
                  <Label htmlFor="schedule" className="mb-0">
                    Monthly Update
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            {/* --- Row 5: Submit Button --- */}
            <Row style={{ display: "flex", justifyContent: "flex-end", marginRight: "50px" }}>
              <Button color="primary" type="submit" disabled={saving}>
                {saving ? "Saving..." : "Save File & Update"}
              </Button>
            </Row>
          </Form>
          <ToastContainer />
        </CardBody>
      </Card>
    </div>
  </>
);


}

export default VolumeRetrieveEditForm
