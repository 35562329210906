import auth0 from 'auth0-js'
import { AUTH_CONFIG } from './auth0-variables'
import { api } from '../utils/index'

class Auth {
  accessToken
  idToken
  expiresAt
  userProfile
  userGroup
  tokenRenewalTimeout

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientID,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.apiUrl,
    responseType: 'token id_token',
    scope: 'openid profile'
  })

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getIdToken = this.getIdToken.bind(this)
    this.renewSession = this.renewSession.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.getExpiryDate = this.getExpiryDate.bind(this)
    this.scheduleRenewal()
  }

  getAccessToken() {
    return this.accessToken
  }

  getIdToken() {
    return this.idToken
  }

    getAllowedServices() {
        return api.get('allowed_services/').then(response => response.data.allowedServices);
    }

  getProfile() {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(this.accessToken, (err, profile) => {
        if (err) return reject(err)
        if (!profile) {
          return reject(err)
        }

        this.userProfile = profile
        api.get(`groups/current/`).then((response) => {
          this.userGroup = (response?.data?.name) ? response.data.name : null
          resolve()
        })
      })
    })
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt))
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt
    return new Date().getTime() < expiresAt
  }

  login(referrer) {
    this.auth0.authorize({
      redirectUri: `${AUTH_CONFIG.callbackUrl}?referrer=${referrer}`
    })
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }

        this.setSession(authResult)
        resolve()
      })
    })
  }

  setCookie(c_name, value, exdate) {
    const c_value = value + ((exdate == null) ? "" : "; expires=" + exdate);
    document.cookie = c_name + "=" + c_value;
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true')

    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    this.accessToken = authResult.accessToken
    this.idToken = authResult.idToken
    this.expiresAt = expiresAt

    this.setCookie('ranking_auth', this.accessToken, this.expiresAt)

    // schedule a token renewal
    this.scheduleRenewal()
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          this.logout()
          return reject(err)
        }
        if (!authResult || !authResult.idToken) {
          this.logout()
          return reject(err)
        }

        this.setSession(authResult)
        resolve()
      })
    })
  }

  logout() {
    // Remove tokens, expiry time and userProfile
    this.accessToken = null
    this.idToken = null
    this.expiresAt = 0
    this.userProfile = null
    this.userGroup = null

    // Clear token renewal
    clearTimeout(this.tokenRenewalTimeout)

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn')
    this.auth0.logout({
      returnTo: window.location.origin
    })
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt
    const timeout = expiresAt - Date.now()
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession().then(() => {
          return
        })
      }, timeout)
    }
  }
}

const auth = new Auth()

export default auth
