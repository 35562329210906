import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../../tagging_tool/components/Loading'
import { api } from '../../utils'
import {
	Card,
	CardHeader,
	Row,
  Col
} from "reactstrap";
import SetTitle from '../../utils/set_title'

export default function EmailAlertsRead() {
  SetTitle('Seo Monitoraggio Siti')
	const params = useParams()
  const [readed, setReaded] = useState(false)
  const [loading, setLoading] = useState(false)

	const readEmail = () => {
    setLoading(true)

		api.get("/seo-sentinel/email-alerts/"+params.id+'/')
      .then(response => {
          setLoading(false)

          if (response?.data?.readed)
            setReaded(response?.data?.readed)
      })
      .catch(err => {
        setLoading(false)
        setReaded(false)
      })
	}

  useEffect(() => {
    readEmail()
  }, [])

	return (
		<>
      <div className="bg-gradient-info secondary-page-title" >
        <div md={12} className="form-title">
          <i className="ni ni-compass-04 text-white" style={{color: "white", fontSize: "35px"}}></i>
          <h1 className="page-title">Seo Monitoraggio Siti</h1>
        </div>
      </div>
      <div className="page-description">
        <div className="header pb-4 pt-5 pt-md-8">
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row style={{ marginLeft: "0px" }}>
                  <Col md={10}>
                    {(loading) ? (
                      <Loading />
                    ) : (readed) ? (
                      <h1 className="mb-0">Lettura mail confermata</h1>
                    ) : (
                      <h1 className="mb-0">Errore: lettura mail non può essere confermata</h1>
                    )}
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            </div>
          </Row>
        </div>
      </div>
		</>
	)
}
